









































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Common from '../../common/Common'
import { Carousel, Slide } from 'vue-carousel'
import firebase from 'firebase/app'
import 'firebase/firestore'
import removeMd from 'remove-markdown'

@Component({
  components: {
    Carousel,
    Slide
  },
})
export default class Top extends Vue {
  rMd = removeMd
  canAdvanceForward = true
  canAdvanceBackward = false
  questions: any = {}
  classes: any = {}
  questionsNavigate: any = {}
  classesNavigate: any = {}
  news: any[] = []

  mounted () {
    window.onresize = this.resizer

    let promises: any[] = []
    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('onayamis').where('type', '==', 'question').orderBy('createTime', 'desc').limit(10).get().then((data) => {
        let onayamis: any[] = []
        data.forEach((onayami) => {
          let value = onayami.data()
          let obj: any = {id: onayami.id, title: value.title, detail: value.detail, tags: value.tags, date: value.date, reward: value.reward, category: value.category, finish:value.finish, roomCount: value.roomCount ? value.roomCount : 0, userName: value.userName, createTime: value.createTime.toDate(), uid: value.uid}
          obj.url = Common.createUserIconURL(obj.uid, value.userFileExist)
          onayamis.push(obj)
        })
        this.questions['0'] = {name: 'すべてのカテゴリー', onayamis: onayamis}
        resolve()
      })
    }))

    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('onayamis').where('type', '==', 'class').where('finish', '==', false).orderBy('createTime', 'desc').limit(10).get().then((data) => {
        let onayamis: any[] = []
        data.forEach((onayami) => {
          let value = onayami.data()
          let obj: any = {id: onayami.id, title: value.title, detail: value.detail, tags: value.tags, date: value.date, reward: value.reward, category: value.category, finish:value.finish, roomCount: value.roomCount ? value.roomCount : 0, userName: value.userName, rate: value.rate, rateNum: value.rateNum, restSlot: value.peopleLimit - value.inClass, createTime: value.createTime.toDate(), uid: value.uid}
          obj.url = Common.createUserIconURL(obj.uid, value.userFileExist)
          obj.rateIcons = Common.createRateIcon(obj.rate)
          onayamis.push(obj)
        })
        this.classes['0'] = {name: 'すべてのカテゴリー', onayamis: onayamis}
        resolve()
      })
    }))
    
    let newsCollection = firebase.firestore().collection('news').where('public', '==', true)
    if (!Common.getUserId()) {
      newsCollection = newsCollection.where('onlyLogin', '==', false)
    }
    newsCollection.orderBy('createTime', 'desc').limit(5).get().then((data) => {
      data.forEach((news) => {
        let result = news.data()
        result.id = news.id
        result.createTime = result.createTime.toDate()
        result.updateTime = result.updateTime.toDate()
        this.news.push(result)
      })
    })

    promises.push(new Promise((resolve) => {
      let categoryPromises: any[] = []
      Common.getCategory().then((categories: any) => {
        categories.forEach((category: any) => {
          if (category.topView) {
            categoryPromises.push(new Promise((categoryResolve) => {
              firebase.firestore().collection('onayamis').where('type', '==', 'question').where('category', '==', category.text).orderBy('createTime', 'desc').limit(10).get().then((data) => {
                let onayamis: any[] = []
                data.forEach((onayami) => {
                  let value = onayami.data()
                  let obj: any = {id: onayami.id, title: value.title, detail: value.detail, tags: value.tags, date: value.date, reward: value.reward, category: value.category, finish:value.finish, roomCount: value.roomCount ? value.roomCount : 0, userName: value.userName, createTime: value.createTime.toDate(), uid: value.uid}
                  obj.url = Common.createUserIconURL(obj.uid, value.userFileExist)
                  onayamis.push(obj)
                })
                this.questions[category.value] = {name: category.text, onayamis: onayamis}
                categoryResolve()
              })
            }))
          }
        })
        Promise.all(categoryPromises).finally(() => {resolve()})
      })
    }))

    promises.push(new Promise((resolve) => {
      let categoryPromises: any[] = []
      Common.getCategory().then((categories: any) => {
        categories.forEach((category: any) => {
          if (category.topView) {
            categoryPromises.push(new Promise((categoryResolve) => {
              firebase.firestore().collection('onayamis').where('type', '==', 'class').where('category', '==', category.text).where('finish', '==', false).orderBy('createTime', 'desc').limit(10).get().then((data) => {
                let onayamis: any[] = []
                data.forEach((onayami) => {
                  let value = onayami.data()
                  let obj: any = {id: onayami.id, title: value.title, detail: value.detail, tags: value.tags, date: value.date, reward: value.reward, category: value.category, finish:value.finish, roomCount: value.roomCount ? value.roomCount : 0, userName: value.userName, rate: value.rate, rateNum: value.rateNum, restSlot: value.peopleLimit - value.inClass, createTime: value.createTime.toDate(), uid: value.uid}
                  obj.url = Common.createUserIconURL(obj.uid, value.userFileExist)
                  obj.rateIcons = Common.createRateIcon(obj.rate)
                  onayamis.push(obj)
                })
                this.classes[category.value] = {name: category.text, onayamis: onayamis}
                categoryResolve()
              })
            }))
          }
        })
        Promise.all(categoryPromises).finally(() => {resolve()})
      })
    }))

    Promise.all(promises).finally(() => {
      this.initNextPrevDisable()
    })
  }

  resizer() {
    if (!(<any>this.$refs.carousel)) {
      window.onresize = () => {}
      return
    }
    this.setNextPrevDisable()
  }

  initNextPrevDisable() {
    try {
      this.setNextPrevDisable()
    } catch(e) {
      setTimeout(() => {
        this.initNextPrevDisable()
      }, 300)
    }
  }

  setNextPrevDisable() {
    this.canAdvanceForward = (<any>this.$refs.carousel).canAdvanceForward;
    this.canAdvanceBackward = (<any>this.$refs.carousel).canAdvanceBackward;
    for (let key in this.questions) {
      this.$set(this.questionsNavigate, key + 'canAdvanceForward', (<any>this.$refs.qcarousel)[key].canAdvanceForward)
      this.$set(this.questionsNavigate, key + 'canAdvanceBackward', (<any>this.$refs.qcarousel)[key].canAdvanceBackward)
    }
    for (let key in this.classes) {
      this.$set(this.classesNavigate, key + 'canAdvanceForward', (<any>this.$refs.ccarousel)[key].canAdvanceForward)
      this.$set(this.classesNavigate, key + 'canAdvanceBackward', (<any>this.$refs.ccarousel)[key].canAdvanceBackward)
    }
  }

  qnextSlide(index: number) {
    (<any>this.$refs.qcarousel)[index].goToPage((<any>this.$refs.qcarousel)[index].getNextPage())
    this.$set(this.questionsNavigate, index + 'canAdvanceForward', (<any>this.$refs.qcarousel)[index].canAdvanceForward)
    this.$set(this.questionsNavigate, index + 'canAdvanceBackward', (<any>this.$refs.qcarousel)[index].canAdvanceBackward)
  }

  qprevSlide(index: number) {
    (<any>this.$refs.qcarousel)[index].goToPage((<any>this.$refs.qcarousel)[index].getPreviousPage())
    this.$set(this.questionsNavigate, index + 'canAdvanceForward', (<any>this.$refs.qcarousel)[index].canAdvanceForward)
    this.$set(this.questionsNavigate, index + 'canAdvanceBackward', (<any>this.$refs.qcarousel)[index].canAdvanceBackward)
  }

  cnextSlide(index: number) {
    (<any>this.$refs.ccarousel)[index].goToPage((<any>this.$refs.ccarousel)[index].getNextPage())
    this.$set(this.classesNavigate, index + 'canAdvanceForward', (<any>this.$refs.ccarousel)[index].canAdvanceForward)
    this.$set(this.classesNavigate, index + 'canAdvanceBackward', (<any>this.$refs.ccarousel)[index].canAdvanceBackward)
  }

  cprevSlide(index: number) {
    (<any>this.$refs.ccarousel)[index].goToPage((<any>this.$refs.ccarousel)[index].getPreviousPage())
    this.$set(this.classesNavigate, index + 'canAdvanceForward', (<any>this.$refs.ccarousel)[index].canAdvanceForward)
    this.$set(this.classesNavigate, index + 'canAdvanceBackward', (<any>this.$refs.ccarousel)[index].canAdvanceBackward)
  }

  nextSlide() {
    (<any>this.$refs.carousel).goToPage((<any>this.$refs.carousel).getNextPage())
    this.canAdvanceForward = (<any>this.$refs.carousel).canAdvanceForward;
    this.canAdvanceBackward = (<any>this.$refs.carousel).canAdvanceBackward;
  }

  prevSlide() {
    (<any>this.$refs.carousel).goToPage((<any>this.$refs.carousel).getPreviousPage())
    this.canAdvanceForward = (<any>this.$refs.carousel).canAdvanceForward;
    this.canAdvanceBackward = (<any>this.$refs.carousel).canAdvanceBackward;
  }

  select(question: firebase.firestore.DocumentData) {
    this.$router.push(`/onayamis/${question.id}`)
  }

  selectClass(clazz: firebase.firestore.DocumentData) {
    this.$router.push(`/onayami/${clazz.id}`)
  }

  isMobile() {
    return Common.isMobile()
  }
}
